import React, { useState } from 'react';
import Logo from '../../../images/Cliente/Logotipo_color_bnzero1.png';
import { login, sendRecoveryEmail } from '../../../services/Auth.service';
import messageBox from '../../../utils/messagebox';

export default function Login({ setUser }) {
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [userNameRecovery, setUserNameRecovery] = useState();
  const [isProcessing, setIsProcessing] = useState(false); // Estado para manejar el procesamiento

  async function _login(e) {
    e.preventDefault();
    await login(userName, password).then(({ data }) => {
      if (data.error) {
        messageBox.Simple(data.message, 'error');
      } else {
        messageBox.Simple(data.message, 'success');
        delete data.message;
        setUser(data);
        setTimeout(() => window.location = '/', 1000);
      }
    });
  }

  async function _sendPasswordRecovery(e) {
    e.preventDefault();
    setIsProcessing(true); // Bloquear botones
    await sendRecoveryEmail(userNameRecovery).then(({ data }) => {
      if (data.error) {
        messageBox.Simple(data.message, 'error');
      } else {
        messageBox.Simple(data.message, 'success');
      }
      setShowForgotPasswordModal(false);
    }).finally(() => {
      setIsProcessing(false); // Desbloquear botones al finalizar
    });
  }

  return (
    <div className="LoginBackground py-6">
      <div className="container login-custom-changed mt-5">
        <div className="authentication-wrapper authentication-basic container-p-y">
          <div className="authentication-inner">
            {/* Logo */}
            <div className="app-brand justify-content-center mb-5">
              <span className="app-brand-logo demo">
                <img src={Logo} style={{ height: "40px", marginBottom: "50px" }} alt="" />
              </span>
            </div>

            {/* Login Card */}
            <div className="card rounded-3xl">
              <div className="card-body lg:px-28 md:px-28 px-10">
                <p className="mb-4" style={{ fontFamily: "Poppins", fontSize: "1em", color: "#B9B9B9", margin: '40px 0' }}>Inicia sesión en tu cuenta</p>
                <form className="mb-3" onSubmit={(e) => _login(e)}>
                  <div className="mb-3">
                    <label htmlFor="email" style={{ textAlign: "center" }}>Usuario ó correo electrónico</label>
                    <input
                      type="text"
                      className="form-control"
                      name="email-username"
                      id='email'
                      onChange={e => setUserName(e.target.value)}
                      style={{ width: "200px", margin: "auto", textAlign: "center" }}
                      autoFocus
                    />
                  </div>
                  <div className="mb-4 form-password-toggle">
                    <label htmlFor="password" style={{ textAlign: "center" }}>Contraseña</label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      id='password'
                      onChange={e => setPassword(e.target.value)}
                      style={{ width: "200px", margin: "auto", textAlign: "center" }}
                    />
                  </div>
                  <button
                    type="button"
                    className="text-center"
                    style={{ fontFamily: "Poppins", fontSize: "16px", color: '#00A297', background: 'none', border: 'none' }}
                    onClick={() => setShowForgotPasswordModal(true)}
                    disabled={isProcessing} // Deshabilitar botón
                  >
                    Olvidé mi contraseña
                  </button>
                  <div className="mb-3">
                    <button className="btn ColorButtonMainInv rounded-2xl d-grid mx-auto w-40 mt-4" type="submit" disabled={isProcessing}>Ingresar</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Forgot Password Modal */}
      {showForgotPasswordModal && (
        <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
          <form className="mb-3" onSubmit={(e) => _sendPasswordRecovery(e)}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" style={{ fontFamily: "Poppins", fontSize: "1em", color: "#B9B9B9" }}>Olvidé mi contraseña</h5>
                </div>
                <div className="modal-body">
                  <p>Por favor, ingresa tu correo electrónico para restablecer tu contraseña.</p>
                  <input
                    type="email"
                    className="form-control"
                    onChange={e => setUserNameRecovery(e.target.value)}
                    placeholder="Correo electrónico"
                    style={{ marginBottom: '15px', marginTop: '15px' }}
                    disabled={isProcessing} // Deshabilitar input
                  />
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn" onClick={() => setShowForgotPasswordModal(false)} disabled={isProcessing}>Cancelar</button>
                  <button type="submit" className="btn" disabled={isProcessing}>Enviar</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
