import { url } from "../api";
import http from "./../utils/http";


export function login(userName, password) {
  return http.post(`${url}/api/auth`, JSON.stringify({ userName, password }));
}

export function sendRecoveryEmail(email) {
  return http.post(`${url}/api/auth/recovery`, JSON.stringify({ email }));
}

export function recoverAccount(pwd, token) {
  return http.post(`${url}/api/auth/recover/account`, JSON.stringify({ pwd, token }));
}
