export function setUser(user = {}) {
  try {
    let suser = JSON.stringify(user);
    window.localStorage.setItem("user", suser);
  } catch (error) {
    console.error("Error setting user in localStorage", error);
  }
}

export function getUser() {
  try {
    let suser = window.localStorage.getItem("user");
    return suser ? JSON.parse(suser) : null;
  } catch (error) {
    console.error("Error getting user from localStorage", error);
    return null;
  }
}

export function getToken() {
  let user = getUser();
  return user ? user.token : undefined;
}

function hasOption(user, opt) {
  if (!user || !user.perfil) return false;
  return user.perfil.includes(opt);
}

export function getOption(opt) {
  let user = getUser();
  return hasOption(user, opt) || opt === "AA";
}

export function getOptionExplicit(opt) {
  let user = getUser();
  return hasOption(user, opt);
}

export function removeUser() {
  try {
    window.localStorage.removeItem("user");
  } catch (error) {
    console.error("Error removing user from localStorage", error);
  }
}
