import React, { useState, useEffect } from "react";
import {
    addCover,
    delCover,
    getCover,
    getCoverActive,
    getAllCoverImagesByType,
    setCoverActive,
} from "../../../services/Admin.service";

function PdfCover({type, selectedImage, setSelectedImage}) {

    const [imageSources, setImageSources] = useState([]);
    const [imageIds, setImageIds] = useState([]);
    //const [selectedImageId, setSelectedImageId] = useState(0);

    useEffect(() => {
        const fetchImages = async () => {
          try {
            const images = await getAllCoverImagesByType(type);

            // Convertir los blobs a URLs de imágenes
            const imageUrls = images.map(image => {
              const blob = new Blob([new Uint8Array(image.data.data)], { type: 'image/png' }); // Ajusta el tipo de imagen según sea necesario
              return URL.createObjectURL(blob);
            });
    
            setImageSources(imageUrls);

            const ids = images.map(image => image.id)
            setImageIds(ids[0]); // Guardar los IDs en el estado
            console.log("imageIds: " + ids[0]);
            
            // Cargar la imagen seleccionada desde localStorage si existe
            const storedIndex = localStorage.getItem(`${type}Active`);
            if (storedIndex !== null && imageUrls[storedIndex]) {
                setSelectedImage(imageUrls[storedIndex]);
            }

            //console.log("currentSelected image ID: " + storedIndex);
            //console.log("ids despues de guardar: " + imageIds);
            
          } catch (error) {
            console.error('Error al obtener las imágenes:', error);
          }
        };
    
        fetchImages();
      }, [type]);


    const handleImageClick = (src, index) => {
        setSelectedImage(src);
        //console.log("index::::>" + index);
        setCoverActive(imageIds[index], type);
        console.log("plplplplp>" + imageIds[index]);
        localStorage.setItem(`${type}Active`, imageIds[index]); // Guarda la selección en localStorage
        //console.log("current" + type + index);
    };

    return (
        <>
            <div
                className="image-container flex"
                style={{
                    display: 'flex',
                    overflowX: 'auto',
                    whiteSpace: 'nowrap',
                    maxWidth: '100%',
                    padding: '10px',
                    borderRadius: "25px",
                    marginTop: '25px',
                }}
            >
                {imageSources.length === 0 ? (
                    <p>No hay imágenes disponibles</p>
                ) : (
                    imageSources.map((src, index) => (
                        <img
                            key={index}
                            alt={`coverPhoto-${index}`}
                            src={src}
                            className="image-item"
                            onClick={() => handleImageClick(src, index)}
                            style={{
                                width: '170px',
                                height: '242px',
                                marginRight: '10px',
                                cursor: 'pointer',
                                border: src === selectedImage ? '3px solid blue' : 'none',
                            }}
                        />
                    ))
                )}
            </div>
        </>
    );
}

export default PdfCover;
