import React, { useState, useEffect } from 'react';
import Avatar1 from "../images/avatars/1.png";
import { obtenerImagenPorId } from '../services/Client.service';

function ClientComponent({ clienteId }) {
  const [imageSrc, setImageSrc] = useState(Avatar1); // Estado para la imagen actual
  
  //console.log("ClientComponent rendered with clienteId:", clienteId);

  useEffect(() => {
    const fetchImage = async () => {
      //console.log("Fetching image for clienteId:", clienteId); // Verifica el clienteId
      try {
        const imageBlob = await obtenerImagenPorId(clienteId);
        //console.log("Image blob received:", imageBlob); // Verifica el blob recibido
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setImageSrc(imageObjectURL);
      } catch (error) {
        console.error('Error al obtener la imagen:', error);
      }
    };

    if (clienteId) {
      fetchImage();
    } else {
      console.log("No clienteId provided");
    }
  }, [clienteId]);

  return (
    <div className="w-20 h-20 mt-4">
      <img
        src={imageSrc}
        alt="User Avatar"
        className="w-full h-full rounded-full object-cover"
      />
    </div>
  );
}

export default ClientComponent;