import React, { useState, useEffect } from "react";
import {
  addCover,
} from "../../../services/Admin.service";
import Loading from "../../../components/Loading/Loading";
import PdfCover from "./PdfCover";
import SocilaNetworks from "./SocialNetworks";

function Pdf() {
  let [loading, setLoading] = useState(true);
  let [frontActive, setFrontActive] = useState(
    () => JSON.parse(localStorage.getItem('frontActive')) || 1
  );
  let [backActive, setBackActive] = useState(
    () => JSON.parse(localStorage.getItem('backActive')) || 2
  );
  let [error, setError] = useState(""); // Nuevo estado para manejar errores

  useEffect(() => {
    setLoading(false);
  }, []);

  async function uploadNewImage(files, type) {
    if (files.length === 0) {
      return alert("Debes escoger una imagen.");
    }
    let picture = new FormData();
    picture.append("picture", files[0]);
    picture.append("type", type);
    try {
      const response = await addCover(picture);
      console.log('File uploaded successfully:', response);
      setError(""); // Limpiar error si la carga fue exitosa
    } catch (err) {
      console.error("Error adding cover photo:", err);
      setError("Error al subir la imagen. Inténtalo de nuevo.");
    }
  }
  
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="ml-8 mt-10">
            <h1
              className="mb-2 font-weight-bold"
              style={{
                fontFamily: "Poppins",
                fontSize: "40px",
                marginTop: "10px",
                marginBottom: "20px",
                color: "rgb(0, 71, 80)",
                fontWeight: "700",
              }}>
              Configuraciones del pdf portada
            </h1>
            <p className="mb-2">Dimensiones recomendadas 512px X 725px, si la sección se encuentran sin ningún valor predeterminado se seleccionara la imagen por defecto del catalogo.</p>
            <div className="md:col-span-11 flex">
              <input
                id="inputImage"
                type="file"
                accept="image/*"
                className="hidden"
                onChange={({ target: { files } }) => uploadNewImage(files, 'front')}
              />
              <label
                htmlFor="inputImage"
                className="btn rounded-pill ColorButtonMainInv lg:text-sm xl:text-md mr-4">
                Agregar
              </label>
            </div>
          </div>
          {error && <div className="error-message">{error}</div>} {/* Mostrar mensaje de error */}
          <PdfCover 
          type="front"
          selectedImage={frontActive}
          setSelectedImage={setFrontActive}/>
          <div className="ml-8 mt-10">
            <h1
              className="mb-2 font-weight-bold"
              style={{
                fontFamily: "Poppins",
                fontSize: "40px",
                marginTop: "10px",
                marginBottom: "20px",
                color: "rgb(0, 71, 80)",
                fontWeight: "700",
              }}>
              Configuraciones del pdf contra-portada
            </h1>
            <p className="mb-2">Dimensiones recomendadas 512px X 725px, si la sección se encuentran sin ningún valor predeterminado se seleccionara la imagen por defecto del catalogo.</p>
            <div className="md:col-span-11 flex">
              <input
                id="inputImage2"
                type="file"
                accept="image/*"
                className="hidden"
                onChange={({ target: { files } }) => uploadNewImage(files, 'back')}
              />
              <label
                htmlFor="inputImage2"
                className="btn rounded-pill ColorButtonMainInv lg:text-sm xl:text-md mr-4">
                Agregar
              </label>
            </div>
          </div>
          <div>
          </div>
          <PdfCover 
          type="back"
          selectedImage={backActive}
          setSelectedImage={setBackActive}/>
          <SocilaNetworks />
        </>
      )}
    </>
  );
}

export default Pdf;
