import React, { useState, useEffect } from "react";

import {  
  getAllSocialNetworks, 
  addSocialNetwork, 
  removeSocialNetwork } from "../../../services/Admin.service";

function SocilaNetworks() {
    /*const [socialMedia, setSocialMedia] = useState([
        { name: 'Facebook', icon: 'https://img.icons8.com/?size=100&id=S1KxejujDmPo&format=png&color=000000', url: 'https://facebook.com' },
        { name: 'Twitter', icon: 'https://img.icons8.com/?size=100&id=vslECYNRa3y8&format=png&color=000000', url: 'https://twitter.com' },
        { name: 'LinkedIn', icon: 'https://img.icons8.com/?size=100&id=Pz213it3TQxp&format=png&color=000000', url: 'https://instagram.com' }
      ]);*/
    
    const [socialMedia, setSocialMedia] = useState([]);
    
    useEffect(() => {
      const fetchNetworks = async () => {
        const res = await getAllSocialNetworks();
        //console.log("nets: " + JSON.stringify(res, null, 2));
        setSocialMedia(res);
      };
      
      fetchNetworks();
    }, []);

    // Manejar el cambio en los campos de entrada
    const handleInputChange = (index, event, field) => {
        const newSocialMedia = [...socialMedia];
        newSocialMedia[index][field] = event.target.value;
        setSocialMedia(newSocialMedia);
    };

    // Manejar la adición de un nuevo campo
    const handleAddField = () => {
        setSocialMedia([
        ...socialMedia,
        { name: '', icon: '', url: '' }
        ]);
    };

    // Manejar la eliminación de un campo
    const handleRemoveField = (index) => {
        setSocialMedia(socialMedia.filter((_, i) => i !== index));
        //console.log(":)" + socialMedia[index].name);
        removeSocialNetwork(socialMedia[index].name);
    };

    // Save Networks in DB
    const handleSaveNetworks = async () => {
      try {
        // Iterar sobre cada red social y guardarla en la base de datos
        const savePromises = socialMedia.map(async (net) => {
          if (net.name && net.url && net.icon) {  // Asegúrate de que los campos necesarios no estén vacíos
            await addSocialNetwork(net.name, net.url, net.icon);
          }
        });
    
        // Esperar a que todas las promesas se completen
        await Promise.all(savePromises);
        
        // Mostrar una notificación de éxito o realizar alguna acción
        console.log('Todas las redes sociales se guardaron exitosamente.');
      } catch (error) {
        console.error('Error al guardar las redes sociales:', error);
        // Mostrar un mensaje de error o manejarlo de alguna manera
      }
    };

    return (
        <>
            <div className="ml-8 mt-10">
            <h2
              className="mb-2 font-weight-bold"
              style={{
                fontFamily: "Poppins",
                fontSize: "40px",
                marginTop: "10px",
                marginBottom: "20px",
                color: "rgb(0, 71, 80)",
                fontWeight: "700",
              }}>
              Redes sociales
            </h2>
            </div>
            <div
                className="image-container flex"
                style={{
                    display: 'flex',
                    overflowX: 'auto',
                    whiteSpace: 'nowrap',
                    maxWidth: '100%',
                    padding: '10px',
                    borderRadius: "25px",
                    marginTop: '25px',
                }}
            >
                <div className="flex flex-col gap-2">
      {socialMedia.map((media, index) => (
        <div key={index} className="flex items-center gap-2 border p-2 rounded">
          <input
            type="text"
            placeholder="Nombre"
            value={media.name}
            onChange={(event) => handleInputChange(index, event, 'name')}
            className="p-2 border rounded"
          />
          <input
            type="text"
            placeholder="URL del ícono"
            value={media.icon}
            onChange={(event) => handleInputChange(index, event, 'icon')}
            className="p-2 border rounded"
          />
          <input
            type="text"
            placeholder="URL"
            value={media.url}
            onChange={(event) => handleInputChange(index, event, 'url')}
            className="p-2 border rounded"
          />
          <button
            onClick={() => handleRemoveField(index)}
            className="bg-red-500 text-white p-1 rounded"
          >
            Eliminar
          </button>
          <a href={media.url} target="_blank" rel="noopener noreferrer" className="flex items-center gap-2">
            {media.icon && (
              <img
                src={media.icon}
                alt={media.name}
                className="w-6 h-6"
                style={{ objectFit: 'cover' }}
              />
            )}
            <span>{media.name}</span>
          </a>
        </div>
      ))}
      <button
        onClick={handleAddField}
        className="bg-blue-500 text-white p-2 rounded mt-2"
      >
        Añadir Red Social
      </button>
      <button
        onClick={handleSaveNetworks}
        className="bg-green-500 text-white p-2 rounded mt-2"
      >
        Guardar
      </button>
    </div>
                
            </div>
        </>
    );
}

export default SocilaNetworks;