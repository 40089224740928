import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";

import React from "react";
import Login from "./pages/Users/Login";
import Layout from "./components/Layout/Layout";
import { UserProvider, UserContext } from "./context/UserContext";
import RecoveryPassword from "./pages/Users/Recovery";

function App() {
  return (
    <div className="">
      <UserProvider>
        <UserContext.Consumer>
          {(context) => {
            const { user: { token } = {}, setUser, clearUser } = context;
            return token ? (
              <Layout clearUser={clearUser} />
            ) : (
              <Router>
                <Routes>
                  <Route path="/recovery" element={<RecoveryPassword />} />
                  <Route path="/" element={<Login setUser={setUser} />} />
                </Routes>
              </Router>
            );
          }}
        </UserContext.Consumer>
      </UserProvider>
    </div>
  );
}

export default App;
