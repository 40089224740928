import React, { useState, useEffect, useContext, useRef } from "react";
import {
  deleteQuotes,
  getQuotes,
  updateQuoteStatus,
} from "../../services/Quotes.service";
import Modal from "./ModalEditQuote";
import messageBox from "../../utils/messagebox";
import { UserContext } from "../../context/UserContext";
//import { formatDate } from "../../utils/tools";
import { getClient } from "../../services/Client.service";
import Loading from "../../components/Loading/Loading";
import LogoSearch from "../../images/Home/Vector.png";
//import IconEdit from "../../images/Cotizaciones/Vector.png";
//import IconDelete from "../../images/Cotizaciones/mdi_delete-outline.png";
import { getProjectId } from "../../services/Projects.service";
import { useNavigate } from "react-router";
import QuoteTableBody from "./QuoteTableBody";

function Quotes() {
  const [loading, setLoading] = useState(true);
  const [quotes, setQuotes] = useState([]);
  const [activeModal, setActiveModal] = useState(false);
  const [search, setSearch] = useState("");
  const [quotesSearch, setQuotesSearch] = useState([]);
  const [dataProject, setDataProject] = useState({});
  const [dataClient, setDataClient] = useState({});
  const [status, setStatus] = useState("noState");
  const [isUpdate, setIsUpdate] = useState(false);
  //const [currentPage, setCurrentPage] = useState(0);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const userData = useContext(UserContext).user;
  const navigate = useNavigate();
  const elementRef = useRef(null);

  useEffect(() => {
    getQuotesData();
  }, [userData]);

  async function getQuotesData() {
    await getQuotes(userData.role < 3 ? userData.id : 0)
      .then(({ data }) => {
        data.Quotes = data.Quotes.sort((a, b) => a.id - b.id);
        setQuotes(data.Quotes);
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error:', err);
        messageBox.Simple(err, "error");
      });
  }

  async function editQuote(project, isUpdate) {
    await getProjectId(project.projectId).then(async ({ data }) => {
      if (data.error) return messageBox.Simple(data.message, "error");
      data = { ...data, ...project };
      setDataProject(data);
      await getClient(project.clientId)
        .then(({ data }) => {
          if (data.error) return messageBox.Simple(data.message, "error");
          setDataClient(data);
          setIsUpdate(isUpdate);
          setActiveModal(true);
        })
        .catch((err) => messageBox.Simple(err, "error"));
    });
  }

  /*async function changeStatus(id, value) {
    setStatus(value);
    await updateQuoteStatus(id, value).then(({ data }) => {
      if (data.error) {
        messageBox.Simple(data.message, "error");
      } else {
        messageBox.Simple(data.message, "success");
        setTimeout(() => window.location.reload(), 1000);
      }
    });
  }*/

  function searchFilter(value) {
    const dataFilter = quotes
      .filter((project) => {
        return project.title.toLowerCase().includes(value.toLowerCase())
          ? project.title.toLowerCase().includes(value.toLowerCase())
          : project.id
              .toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase());
      })
      .map((client) => client);
    setSearch(value);
    setQuotesSearch(dataFilter);
  }

  async function deleteQuote(idProject) {
    await deleteQuotes(idProject)
      .then(({ data }) => {
        if (data.error) {
          messageBox.Simple(data.message, "error");
        } else {
          messageBox.Simple(data.message, "success");
          setTimeout(() => window.location.reload(), 2000);
        }
      })
      .catch((err) => messageBox.Simple(err, "error"));
  }

  const stylesStatus = {
    AP: { color: "text-lime-500", text: "Aprobado" },
    MD: { color: "text-sky-400", text: "Modificado" },
    PE: { color: "text-yellow-400", text: "Pendiente" },
    NE: { color: "text-red-500", text: "Rechazado" },
  };

  function redirectQuoteHistory(projectData) {
    navigate("../quotesHistory", { state: { projectData } });
  }

  useEffect(() => {
    if (elementRef.current) {
      const rect = elementRef.current.getBoundingClientRect();
      //console.log(rect);
    }
  }, []);

  function handleShowPopup() {
    if (elementRef.current) {
      const rect = elementRef.current.getBoundingClientRect();
      //console.log(rect);
      setPopupPosition({
        top: rect.top + window.scrollY,
        left: rect.left + window.scrollX
      });
    } else {
      //console.log("Popup postition: " + popupPosition);
      console.error('Element not found');
    }
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="ml-8 mt-10">
            <h1 className="mb-2 font-weight-bold" style={{ fontFamily: "Poppins", fontSize: "40px", marginTop: "10px", marginBottom: "20px", color: "rgb(0, 71, 80)", fontWeight: "700" }}>
              Cotizaciones 
            </h1>
          </div>
          <div className="flex flex-col mx-6">
            <div className="overflow-x-auto">
              <div className="py-3 pl-2">
                <div className="relative">
                  <div className="lg:flex block justify-between">
                    <div className="lg:flex lg:w-1/2">
                      <div className="mb-3" style={{ position: "relative" }}>
                        <input
                          id="defaultInput"
                          className="form-control rounded-pill lg:w-96 lg:14"
                          style={{ backgroundColor: "#F1F1F1", color: "#B9B9B9", borderBlockColor: "#B9B9B9", paddingLeft: "40px" }}
                          value={search}
                          onChange={(e) => searchFilter(e.target.value)}
                          type="text"
                          placeholder="Ingrese el ID o el nombre del proyecto"
                          disabled={quotes.length === 0}
                        />
                        <img
                          src={LogoSearch}
                          alt="Lupa de búsqueda"
                          style={{ position: "absolute", top: "50%", left: "15px", transform: "translateY(-50%)" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-1.5 w-full inline-block align-middle">
                {quotes.length === 0 ? (
                  <h2 className="p-3 text-lg font-semibold text-center">
                    No sé encontró ninguna cotización realizada por usted.
                  </h2>
                ) : (
                  <div className="overflow-auto border rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200 table-auto">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="px-6 py-3 text-xs font-bold text-center text-gray-500 uppercase">
                            ID Cotización
                          </th>
                          <th scope="col" className="px-6 py-3 text-xs font-bold text-center text-gray-500 uppercase">
                            <span className="inline-flex items-center">Estatus</span>
                          </th>
                          <th scope="col" className="px-6 py-3 text-xs font-bold text-center text-gray-500 uppercase">
                            <span className="inline-flex items-center">Proyecto</span>
                          </th>
                          {/*<th scope="col" className="px-6 py-3 text-xs font-bold text-center text-gray-500 uppercase">
                            <span className="inline-flex items-center">Referencia</span>
                          </th>-->
                          <th scope="col" className="px-6 py-3 text-xs font-bold text-center text-gray-500 uppercase">
                            <span className="inline-flex items-center">Alcances del servicio a realizar</span>
                          </th>*/}
                          <th scope="col" className="px-6 py-3 text-xs font-bold text-center text-gray-500 uppercase">
                            <span className="inline-flex items-center">Precio</span>
                          </th>
                          <th scope="col" className="px-6 py-3 text-xs font-bold text-center text-gray-500 uppercase">
                            <span className="inline-flex items-center">Fecha envío</span>
                          </th>
                        </tr>
                      </thead>
                      <QuoteTableBody
                        quotes={quotes}
                        search={search}
                        quotesSearch={quotesSearch}
                        stylesStatus={stylesStatus}
                        handleShowPopup={handleShowPopup}
                        editQuote={editQuote}
                        deleteQuote={deleteQuote}
                        redirectQuoteHistory={redirectQuoteHistory}
                      />
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
          {activeModal && (
        <div
          className="App h-screen ml-[90px] flex flex-col items-center justify-center bg-purple-200 vw-100 vh-100"
          style={{
            zIndex: "10",
            background: "rgba(0, 162, 151, .55)",
            position: "fixed",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          <Modal
            Context={userData}
            clientId={dataProject.idClient}
            dataClient={dataClient}
            dataProject={dataProject}
            projectId={dataProject.projectId}
            setShowModalCot={setActiveModal}
            isUpdate={isUpdate}
            setIsUpdate={setIsUpdate}
          />
        </div>
      )}
          
        </>
      )}
    </>
  );
}

export default Quotes;
