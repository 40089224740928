import React, { useState } from 'react';
import Logo from '../../../images/Cliente/Logotipo_color_bnzero1.png';
import messageBox from '../../../utils/messagebox';
import { recoverAccount } from '../../../services/Auth.service';
import { useSearchParams, useNavigate } from "react-router-dom";

export default function RecoveryPassword({ setUser }) {
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [searchParams] = useSearchParams();
  const [isProcessing, setIsProcessing] = useState(false); // Estado para manejar el procesamiento
  const token = searchParams.get("token");
  const navigate = useNavigate(); // Hook para navegar entre rutas.

  async function _recoverAccount(e) {
    e.preventDefault();
    if (!token) {
      messageBox.Simple('Token incorrecto o expirado.', 'error');
      return;
    }
    if (password !== confirmPassword) {
      messageBox.Simple('Las contraseñas no coinciden.', 'error');
      return;
    }
    setIsProcessing(true); // Bloquear botones
    await recoverAccount(password, token).then(({ data }) => {
      if (data.error) {
        messageBox.Simple(data.message, 'error');
      } else {
        messageBox.Simple('Contraseña de la cuenta actualizada con éxito.', 'success');
        navigate("/");
      }
    }).finally(() => {
      setIsProcessing(false); // Desbloquear botones al finalizar
    });
  }

  return (
    <div className="LoginBackground py-6">
      <div className="container login-custom-changed mt-5">
        <div className="authentication-wrapper authentication-basic container-p-y">
          <div className="authentication-inner">
            {/* Logo */}
            <div className="app-brand justify-content-center mb-5">
              <span className="app-brand-logo demo">
                <img src={Logo} style={{ height: "40px", marginBottom: "50px" }} alt="" />
              </span>
            </div>

            {/* Login Card */}
            <div className="card rounded-3xl">
              <div className="card-body lg:px-28 md:px-28 px-10">
                <p className="mb-4" style={{ fontFamily: "Poppins", fontSize: "1em", color: "#B9B9B9", margin: '40px 0' }}>Escribe una nueva contraseña</p>
                <form className="mb-3" onSubmit={e => _recoverAccount(e)}>
                  <div className="mb-3">
                    <label htmlFor="password" style={{ textAlign: "center" }}>Contraseña</label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      id='password'
                      onChange={e => setPassword(e.target.value)}
                      style={{ width: "200px", margin: "auto", textAlign: "center" }}
                      autoFocus
                      disabled={isProcessing} // Deshabilitar durante el procesamiento
                    />
                  </div>
                  <div className="mb-4 form-password-toggle">
                    <label htmlFor="confirmPassword" style={{ textAlign: "center" }}>Confirma tu nueva contraseña</label>
                    <input
                      type="password"
                      className="form-control"
                      name="confirmPassword"
                      id='confirmPassword'
                      onChange={e => setConfirmPassword(e.target.value)}
                      style={{ width: "200px", margin: "auto", textAlign: "center" }}
                      disabled={isProcessing} // Deshabilitar durante el procesamiento
                    />
                  </div>
                  <div className="mb-3">
                    <button className="btn ColorButtonMainInv rounded-2xl d-grid mx-auto w-40 mt-4" type="submit" disabled={isProcessing}>
                      Cambiar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
