import axios from "axios";

import { getToken } from "../utils/localuser";
import http from "./../utils/http";

import { url } from "../api";


/* -------------------------------- PRODUCTOS ------------------------------- */
export function getProducts() {
  return http.get(`${url}/api/services`);
}

export function getProductId(id) {
  return http.get(
    `${url}/api/services/${id}`,
    JSON.stringify({ idProject: id })
  );
}

export async function addProducts(data) {
  // console.log(`data ${data}`);
  //console.log(data);
  return await http.post(`${url}/api/services`, JSON.stringify({ ...data }));
}

export function putProducts(data) {
  //console.log(data);
  return http.put(
    `${url}/api/services/putProduct`,
    JSON.stringify({ ...data })
  );
}

export function delProducts(id) {
  return http.post(`${url}/api/services/delProduct`, JSON.stringify({ id }));
}

/* -------------------------------- CONCEPTOS ------------------------------- */

export function getConcept(id) {
  return http.post(`${url}/api/services/listConcept`, JSON.stringify({ id }));
}

export function getConceptId(id) {
  return http.post(`${url}/api/services/listConceptId`, JSON.stringify({ id }));
}

export function getConcepts(id) {
  return http.post(`${url}/api/services/listConcepts`, JSON.stringify({ id }));
}

export function addConcept(data) {
  return http.post(
    `${url}/api/services/addConcept`,
    JSON.stringify({ ...data })
  );
}

export function putConcept(data) {
  return http.post(
    `${url}/api/services/putConcept`,
    JSON.stringify({ ...data })
  );
}

export function delConcept(id) {
  return http.post(`${url}/api/services/delConcept`, JSON.stringify({ id }));
}

export function getTermsAndConditions() {
  return http.post(`${url}/api/admin/terms-and-conditions/get`);
}

export function addTermsAndConditions(data) {
  return http.post(
    `${url}/api/admin/terms-and-conditions/add`,
    JSON.stringify({ ...data })
  );
}

export function updateTermsAndConditions(data) {
  return http.post(
    `${url}/api/admin/terms-and-conditions/update`,
    JSON.stringify({ ...data })
  );
}

export function deleteTermsAndConditions(id) {
  return http.post(
    `${url}/api/admin/terms-and-conditions/delete`,
    JSON.stringify({ id })
  );
}

/* ----------------------------- VALUE OFFERTS -------------------------------*/
export function getAllValueAddedFeatures() {
  return http.get(`${url}/api/admin/value-added-features`);
}

export function createValueAddedFeature(data) {
  return http.post(`${url}/api/admin/value-added-features/add`, JSON.stringify({ ...data }));
}

export function updateValueAddedFeature(data) {
  return http.post(`${url}/api/admin/value-added-features/update`, JSON.stringify({ ...data }));
}

export function deleteValueAddedFeature(id) {
  return http.post(`${url}/api/admin/value-added-features/delete`, JSON.stringify({ id }));
}

/* ----------------------------- ALCANCES RUTAS ----------------------------- */
export function getScope(id) {
  return http.post(`${url}/api/services/listScope`, JSON.stringify({ id }));
}

export function getScopes(id) {
  return http.post(`${url}/api/services/listScopes`, JSON.stringify({ id }));
}

export function getScopeId(id) {
  return http.post(`${url}/api/services/listScopeId`, JSON.stringify({ id }));
}

export function addScope(data) {
  return http.post(`${url}/api/services/addScope`, JSON.stringify({ ...data }));
}

export function putScope(data) {
  return http.post(`${url}/api/services/putScope`, JSON.stringify({ ...data }));
}

export function delScope(id) {
  return http.post(`${url}/api/services/delScope`, JSON.stringify({ id }));
}

/* ----------------------------- PDF COVER PHOTO ---------------------------- */

export async function addCover(formData) {
  console.log("log de addcover: " + formData);

  try {
    const response = await fetch(`${url}/api/admin/addCoverPhoto`, {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': `Bearer ${getToken()}`,// Si se necesita autenticación
      },
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Error: ${response.statusText} - ${errorText}`);
    }

    const data = await response.json();
    return data; // Retorna los datos de la respuesta para su uso
  } catch (error) {
    console.error('Error:', error);
    throw error; // Lanza el error para ser manejado por el llamador
  }
}

export async function getCoverActive(type) {
  //return http.post(`${url}/api/admin/getAllCoverPhotos`, JSON.stringify({}));
  const formData = new FormData();
  formData.append('type', type);

  try {
    const response = await http.post(`${url}/api/admin/getCoverActive`, formData, {
      headers: {
        Authorization: `${getToken()}`,
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'blob', // Asegúrate de que el tipo de respuesta sea blob
    });

    return response.data; // Esto será un blob de la imagen
  } catch (error) {
    console.error('Error al obtener la foto:', error);
    throw error;
  }
}

export async function getAllCoverImagesByType(type) {
  const formData = new FormData();
  formData.append('type', type);

  try {
    const response = await http.post(`${url}/api/admin/getAllCoverImagesByType`, formData, {
      headers: {
        Authorization: `${getToken()}`,
        'Content-Type': 'multipart/form-data', // Especifica el tipo de contenido
      },
    });

    return response.data; // Esto será un blob de la imagen
  } catch (error) {
    console.error('Error al obtener la foto:', error);
    throw error;
  }
}

export async function getCover() {
  return http.post(`${url}/api/admin/getCoverPhotos`, JSON.stringify({}));
}

export function delCover(id) {
  return http.post(`${url}/api/admin/delCoverPhoto`, JSON.stringify({ id }));
}

export function setCoverActive(index, type) {
  return http.post(
    `${url}/api/admin/setCoverActive`,
    JSON.stringify({ index, type })
  );
}

export async function getAllSocialNetworks() {
  try {
    const response = await http.get(`${url}/api/admin/getAllSocialNetworks`, {
      headers: {
        Authorization: `${getToken()}`,
        'Content-Type': 'application/json', // Especifica el tipo de contenido
      },
    });

    return response.data; // Esto será un JSON con la lista de redes sociales
  } catch (error) {
    console.error('Error al obtener las redes sociales:', error);
    throw error;
  }
}

export async function addSocialNetwork(name, url_, icon) {
  const formData = new FormData();
  formData.append('name', name);
  formData.append('url', url_);
  formData.append('icon', icon);

  try {
    const response = await http.post(`${url}/api/admin/addSocialNetwork`, formData, {
      headers: {
        Authorization: `${getToken()}`,
        'Content-Type': 'application/json', // Especifica el tipo de contenido
      },
    });

    return response.data; // Esto será un JSON con la lista de redes sociales
  } catch (error) {
    console.error('Error al guardar la red social:' + name, error);
    throw error;
  }
}

export async function removeSocialNetwork(name) {
  const formData = new FormData();
  formData.append('name', name);

  try {
    const response = await http.post(`${url}/api/admin/removeSocialNetwork`, formData, {
      headers: {
        Authorization: `${getToken()}`,
        'Content-Type': 'application/json', // Especifica el tipo de contenido
      },
    });

    return response.data; // Esto será un JSON con la lista de redes sociales
  } catch (error) {
    console.error('Error al eliminar la red social:' + name, error);
    throw error;
  }
}