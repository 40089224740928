import { url } from "../api";
import http from "./../utils/http";
import { getToken } from "../utils/localuser";

export function updateClientsHubpost(){
  return http.get(`${url}/api/hubpost/client`);
}

export function getClient(id) {
  return http.post(`${url}/api/client`, JSON.stringify({ id }));
}

export function getClientsHubpost(id) {
  return http.post(`${url}/api/client`, JSON.stringify({ id }));
}
//const response = await fetch(`${url}/api/client/agregarImagen`, {
export async function updateFoto (formData){
  console.log("updateFoto----?" + getToken());
  try {
    const response = await fetch(`${url}/api/client/agregarImagen`, {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': `Bearer ${getToken()}`,// Si se necesita autenticación
      },
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Error: ${response.statusText} - ${errorText}`);
    }

    const data = await response.json();
    return data; // Retorna los datos de la respuesta para su uso
  } catch (error) {
    console.error('Error:', error);
    throw error; // Lanza el error para ser manejado por el llamador
  }
}

export async function obtenerFoto(nombreImagen) { //DEPRECATED
  /*const formData = new FormData();
  formData.append('nombreImagen', nombreImagen);

  try {
    const response = await http.post(`${url}/api/client/obtenerImagen`, formData, {
      headers: {
        Authorization: `${getToken()}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error al obtener la foto:', error);
    throw error; // Lanza el error para que pueda ser manejado en el lugar donde se llama a esta función
  }*/
}

export async function obtenerImagenPorId(clienteId) {
  const formData = new FormData();
  formData.append('clientID', clienteId);

  try {
    const response = await http.post(`${url}/api/client/obtenerImagenPorId`, formData, {
      headers: {
        Authorization: `${getToken()}`,
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'blob', // Asegúrate de que el tipo de respuesta sea blob
    });

    return response.data; // Esto será un blob de la imagen
  } catch (error) {
    console.error('Error al obtener la foto:', error);
    throw error;
  }
}